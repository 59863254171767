import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { BackOfficeService } from '@services/back-office.service';
import { TalentService } from '@services/talent.service';
import { MAX_LENGTH, URL_PATTERN } from 'src/app/shared/constants';

@Component({
  selector: 'app-personal-website',
  templateUrl: './personal-website.component.html',
  styleUrls: ['./personal-website.component.scss', '../../../talent/about/about.component.scss']
})
export class PersonalWebsiteComponent {
  public personalWebsiteForm !: FormGroup;
  public isLoading = false;
  public errorMessage = '';
  public oldPersonalWebsite !: string;
  public talentId !: string;

  constructor
    (
      private talentService: TalentService,
      private backOfficeService: BackOfficeService,
      private dialogRef: MatDialogRef<PersonalWebsiteComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private toastManager: ToastManager,
    ) {
    this.personalWebsiteForm = new FormGroup({
      personalWebsite: new FormControl('', [Validators.required, Validators.maxLength(MAX_LENGTH.TEXT_AREA_SMALL), Validators.pattern(URL_PATTERN)]),
    });
    if (modalData?.data) {
      console.log('modalData', modalData.data);
      this.personalWebsiteForm.patchValue({
        personalWebsite: modalData.data.personalWebsite || ''
      })
      this.oldPersonalWebsite = modalData.data.personalWebsite;
      this.talentId = modalData.data.talentId;
    }
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public async updatePersonalWebsite(): Promise<void> {
    try {
      this.isLoading = true;
      const personalWebsite = this.personalWebsiteForm.value.personalWebsite;
      if (StorageHelper.isBackOfficeRole) {
        await this.backOfficeService.updateTalentPersonalWebsite(this.talentId, personalWebsite);
      } else {
        await this.talentService.updatePersonalWebsite(personalWebsite);
      }
      await this.modalData.callback();
      this.dialogRef.close()
      this.toastManager.showSuccess('Personal website saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
