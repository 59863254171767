import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { environment } from '@env/environment';
import { CompanyProfile, JobInfo } from '@interfaces/corporate.interface';
import { BackOfficeService } from '@services/back-office.service';
import { CorporateService } from '@services/corporate.service';
import { ModalInterface, ModalService } from '@services/modal.service';
import { StoreService } from '@services/store.service';
import { isEmpty } from 'lodash';
import { Subject, takeUntil, combineLatest } from 'rxjs';
import { AboutFormComponent } from './about-form/about-form.component';
import { BasicInfoFormComponent } from './basic-info-form/basic-info-form.component';
import { PhotoProfileFormComponent } from '@pages/register/personal-info/photo-profile-form/photo-profile-form.component';
import { UserProfile } from '@interfaces/talent.interface';
import { StorageHelper } from '@helpers/storage.helper';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss', '../../talent/resume/resume.component.scss']
})
export class CompanyProfileComponent implements OnInit {

  public companyProfile !: CompanyProfile | null;
  public userProfile !: UserProfile | null;
  public isLoading: boolean = false;
  public errorMessage = '';
  public isJobExist = false;
  public branches: any[] = [];
  public selectedJob!: JobInfo;
  public companyId!: string | null;
  public title = 'Company';
  public loginAs !: string | null;
  public isBackOfficePage: boolean = environment.isBackOfficePage;
  public pendingTasks = {
    totalJobPost: 0,
    totalOnBoarding: 0,
    totalTalentRequest: 0
  }

  private destroy$ = new Subject<void>();

  constructor(
    private storeService: StoreService,
    private corporateService: CorporateService,
    private modalService: ModalService,
    private router: Router,
    private toastManager: ToastManager,
    private route: ActivatedRoute,
    private backOfficeService: BackOfficeService,
  ) {
    this.storeService.userProfile$.subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
    this.storeService.setActiveSubNavbar('company-profile');
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.storeService.loginAs$,
      this.route.params,
      this.storeService.companyProfile$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        loginAs,
        params,
        companyProfile
      ]: [any, any, any]) => {
        try {
          this.loginAs = loginAs;
          if (loginAs === 'BACK_OFFICE') {
            this.companyId = params['companyId'];
            if (this.companyId) {
              await this.getDetailedCompany(this.companyId);
              if (!this.companyProfile) {
                return;
              }
              const isPendingCompany = this.companyProfile.status === 0;
              if (isPendingCompany) {
                this.title = 'Pending Approval';
              }

              if (this.companyProfile?.jobs.length) {
                this.isJobExist = true;
              }
              if (this.companyProfile) {
                const branches = this.companyProfile.branches.map((branch) => {
                  return {
                    name: branch.name,
                    country: branch.country,
                    city: branch.city,
                    timezone: branch.timezone
                  }
                });
                this.branches = branches;
              }
              await this.getPendingTasks(this.companyId);
              this.backOfficeService.setActiveSidebarMenu('corporate');
              this.backOfficeService.setActiveSidebarSubMenu('details', 'profile');
            }
          }

          if (loginAs === 'CORPORATE') {
            this.storeService.showSubNavbar();
            this.storeService.setActiveSubNavbar('company-profile');
            this.companyProfile = companyProfile;
            if (companyProfile?.jobs.length) {
              this.isJobExist = true;
            }
            if (this.companyProfile) {
              const branches = this.companyProfile.branches.map((branch) => {
                return {
                  name: branch.name,
                  country: branch.country,
                  city: branch.city,
                  timezone: branch.timezone
                }
              });
              this.branches = branches;
            }
          }
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        }
      })

    this.backOfficeService.setIsCorporateDetailMenu(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async getPendingTasks(companyId: string) {
    try {
      this.isLoading = true;
      const totalJobPostTask = await this.backOfficeService.getPendingTask(companyId, 'total_job_post_request');
      const totalOnBoarding = await this.backOfficeService.getPendingTask(companyId, 'total_onboarding_process');
      const totalTalentRequest = await this.backOfficeService.getPendingTask(companyId, 'total_talent_request');
      this.pendingTasks.totalJobPost = totalJobPostTask
      this.pendingTasks.totalOnBoarding = totalOnBoarding
      this.pendingTasks.totalTalentRequest = totalTalentRequest
    } catch (error: any) {
      this.toastManager.showError('Failed to get pending task', error.message)
    } finally {
      this.isLoading = false;
    }
  }


  private async getDasboardData() {
    this.isLoading = true;
    try {
      if (StorageHelper.isBackOfficeRole && this.companyId) {
        const companyProfile = await this.backOfficeService.getCompanyById(this.companyId);
        this.companyProfile = companyProfile;
      } else {
        const companyProfile = await this.corporateService.getCompanyProfile();
        this.companyProfile = companyProfile;
      }

      if (this.companyProfile) {
        const branches = this.companyProfile.branches.map((branch) => {
          return {
            name: branch.name,
            country: branch.country,
            city: branch.city,
            timezone: branch.timezone
          }
        });
        this.branches = branches;
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public editBasicInfo() {
    const modalConfig: ModalInterface = {
      component: BasicInfoFormComponent,
      customLayout: {
        width: '644px',
      },
      data: {
        basicInfo: this.companyProfile,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editPhotoProfile() {
    const modalConfig: ModalInterface = {
      component: PhotoProfileFormComponent,
      customLayout: {
        width: '410px',
      },
      data: {
        basicInfo: this.companyProfile,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editAbout() {
    const modalConfig: ModalInterface = {
      component: AboutFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        companyAbout: this.companyProfile,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public onClickManageJob() {
    if (isEmpty(this.companyProfile?.mainOffice)) {
      this.toastManager.showError('Cannot Create Job Post', "Please enter a main office first")
      return;
    }
    if (this.loginAs === 'CORPORATE') {
      this.router.navigate(['/job/initiation'])
      return
    }
    if (this.loginAs === 'BACK_OFFICE') {
      this.router.navigate([`/corporate/jobs/${this.companyId}/initiation`])
      return
    }
  }

  public onClickPendingTaskButton(url: string) {
    this.backOfficeService.setCurrentPageUrl(url)
    if (this.loginAs === 'CORPORATE') {
      this.router.navigate([url])
      return
    }
    this.router.navigate([url, this.companyId])

  }

  public async selectJob(job: JobInfo) {
    try {
      this.selectedJob = job;
      if (this.loginAs != 'BACK_OFFICE') {
        this.storeService.setLoginAs('CORPORATE');
      }
      this.storeService.setActiveSubNavbar('job')
      if (this.isBackOfficePage) {
        this.router.navigate(['/corporate/details/job-post', job.companyId])
        return;
      }
      this.corporateService.setCardType('viewedBy');
      this.router.navigate(['/corporate/job', job.jobId])
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async getDetailedCompany(companyId: string) {
    try {
      this.isLoading = true;
      this.companyProfile = await this.backOfficeService.getCompanyById(companyId);
    } catch (error: any) {
      this.toastManager.showError('Show Detail Talent Failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public goToManageAccount() {
    if (this.loginAs === 'CORPORATE') {
      this.router.navigate(['/corporate/account'])
      return
    }

    if (this.loginAs === 'BACK_OFFICE') {
      this.backOfficeService.setCurrentPageUrl('corporate/accounts')
      this.router.navigate(['/corporate/accounts', this.companyId])
      return
    }
  }
}
