// Angular modules
import { Injectable } from '@angular/core';
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

// Internal modules
import { environment } from '@env/environment';

// Enums
import { StorageKey } from '@enums/storage-key.enum';
import { JWTLoggedUser } from '@interfaces/authentication.interface';
import { LoginType, UserType } from '@enums/login.enum';

@Injectable()
export class StorageHelper {
  private static storagePrefix: string = environment.appName + '_' + environment.version + '_';

  // ----------------------------------------------------------------------------------------------
  // SECTION Methods ------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------

  // NOTE Token

  public static setAccessToken(token: string, loginType = LoginType.SELF): void {
    let decodedToken = jwtDecode<JWTLoggedUser>(token);
    const accessTokenKey = this.prefixer(StorageKey.ACCESS_TOKEN, true);
    const loginTypeKey = this.prefixer(StorageKey.LOGIN_TYPE, true);
    let expires: Date = new Date(decodedToken.exp * 1000);
    Cookies.set(accessTokenKey, token, {
      expires: expires,
    });
    Cookies.set(loginTypeKey, loginType, {
      expires: expires,
    });
  }

  public static setRefreshToken(refreshToken: string): void {
    let decodedToken = jwtDecode<JWTLoggedUser>(refreshToken);
    const refreshTokenKey = this.prefixer(StorageKey.REFRESH_TOKEN, true);
    let expires: Date = new Date(decodedToken.exp * 1000);

    Cookies.set(refreshTokenKey, refreshToken, {
      expires: expires,
    });
  }

  public static async removeAccessToken(): Promise<void> {
    const accessTokenKey = this.prefixer(StorageKey.ACCESS_TOKEN, true);
    Cookies.remove(accessTokenKey);
  }

  public static getLoginType(): string | undefined {
    const loginTypeKey = this.prefixer(StorageKey.LOGIN_TYPE, true);
    return Cookies.get(loginTypeKey);
  }

  public static removeRefreshToken(): void {
    const accessTokenKey = this.prefixer(StorageKey.REFRESH_TOKEN, true);
    Cookies.remove(accessTokenKey);
  }

  public static removeGoogleState(): void {
    const googleStateKey = this.prefixer(StorageKey.GOOGLE_STATE, false);
    Cookies.remove(googleStateKey);
  }

  public static getAccessToken(): string {
    const accessTokenKey = this.prefixer(StorageKey.ACCESS_TOKEN, true);
    let cookie = Cookies.get(accessTokenKey);
    if (!cookie) return "";
    return cookie;
  }

  static getCurrentLoggedUser = (): JWTLoggedUser | null => {
    if(StorageHelper.getAccessToken()) {
      return jwtDecode<JWTLoggedUser>(StorageHelper.getAccessToken());
    }
    return null;
  }

  static get isBackOfficeRole() {
    const loggedUser = StorageHelper.getCurrentLoggedUser();
    return loggedUser?.type === UserType.BACK_OFFICE
  }

  public static setItem(key: string, value: any, prefix: boolean = true): void {
    const itemKey = this.prefixer(key, prefix);
    localStorage.setItem(itemKey, JSON.stringify(value));
  }

  public static getItem(key: string, prefix: boolean = true): any {
    const itemKey = this.prefixer(key, prefix);
    const res = localStorage.getItem(itemKey);
    if (res !== 'undefined')
      return JSON.parse(res as any);
    return null;
  }

  public static removeItem(key: string, prefix: boolean = true): void {
    const itemKey = this.prefixer(key, prefix);
    localStorage.removeItem(itemKey);
  }

  public static getKeys(all: boolean = false): string[] {
    const keys: string[] = [];
    // NOTE Keys
    for (const key in localStorage)
      keys.push(key);
    if (all)
      return keys;
    // NOTE Prefixed keys
    return keys.filter((item) => item.startsWith(this.storagePrefix));
  }

  public static clearItems(all: boolean = false): void {
    // NOTE Keys
    if (all) {
      localStorage.clear();
      return;
    }
    // NOTE Prefixed keys
    const prefixedKeys = this.getKeys();
    for (const prefixedKey of prefixedKeys)
      this.removeItem(prefixedKey, false);
  }

  public static clearItemsWithoutCurrentPrefix(): void {
    const allKeys = this.getKeys(true);
    for (const key of allKeys)
      if (!key.startsWith(this.storagePrefix))
        this.removeItem(key, false);
  }

  // !SECTION LocalStorage

  // NOTE Private

  private static prefixer(key: string, autoPrefix: boolean): string {
    let itemKey = key;
    if (autoPrefix)
      itemKey = this.storagePrefix + key;
    return itemKey;
  }

  public static checkPermission() {
    const currentUser = StorageHelper.getCurrentLoggedUser();
    if (currentUser?.role === 'User') {
      throw new Error('You are not allowed to do this action');
    }
  }
}
