<div class="job-form" *ngIf="userProfile">
  <div class="basic">
    <div class="basic2" #resize>
      <div class="resume-profile-section">
        <ng-container *ngIf="userProfile.status && 'BO' | roleChecker">
          <img (click)="editPhotoProfile()" *ngIf="!userProfile.profilePictureUrl"
            src="./assets/img/project/default-user-picture.png" class="profile-picture-img cursor-pointer" />
          <img (click)="editPhotoProfile()" *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl"
            class="profile-picture-img cursor-pointer" />
        </ng-container>
        <ng-container *ngIf="!userProfile.status && 'BO' | roleChecker">
          <img *ngIf="!userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
            class="profile-picture-img" />
          <img *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl"
            class="profile-picture-img" />
        </ng-container>
        <ng-container *ngIf="!('BO' | roleChecker)">
          <img *ngIf="!userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
            class="profile-picture-img" />
          <img *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl"
            class="profile-picture-img" />
        </ng-container>
      </div>
      <div class="basic3">
        <div class="basic4">
          <div class="username">{{userProfile.firstName}} {{ userProfile.lastName}}</div>
          <div class="as">
            <div class="front-end-developer-wannabe">
              {{ userProfile.position || '-' }}
            </div>
            <div class="at-company-x">
              <span><span *ngIf="userProfile.currentCompany" class="at-company-x-span">at </span><span
                  class="at-company-x-span2">
                  {{ userProfile.currentCompany || '-' }} {{ userProfile.isLookingForWork ? ' / Looking for work':
                  '' }}
                </span></span>
            </div>
          </div>
          <div class="chip-border" [ngClass]="userProfile.isEnigmaTalent ? 'border-orange': 'border-blue'">
            <label>{{ userProfile.isEnigmaTalent ? 'Enigma Graduates': 'Experience Hiring'}}
            </label>
          </div>
        </div>
        <div [ngSwitch]="cardType" style="width: 100%;">
          <div [ngSwitch]="cardType" class="frame-1632" *ngSwitchCase="'pending-registration'">
            <div class="email">
              <i class="bi bi-at"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.email) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-telephone-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.phone) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
          <div [ngSwitch]="cardType" class="frame-1632" *ngSwitchCase="'back-office'">
            <div class="email">
              <i class="bi bi-at"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.email) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-telephone-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.phone) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
          <div class="frame-1632" *ngSwitchDefault>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngSwitch]="cardType">
        <div class="cta" *ngSwitchCase="'applicant'">
          <div class="btn-rounded bg-success-soft" *ngIf="applicationStatus === 1">
            <label>
              <i class="bi bi-file-earmark-check">
              </i>
              On Process
            </label>
          </div>
          <ng-container>
            <div class="btn-rounded bg-primary-soft" (click)="approveApplicantForJob()" *ngIf="applicationStatus === 0">
              <label>
                <i class="bi bi-file-earmark-check">
                </i>
                Approve
              </label>
            </div>
            <div class="btn-rounded bg-danger-soft" (click)="declineApplicantForJob()" *ngIf="applicationStatus === 0">
              <label>
                <i class="bi bi-file-earmark-excel">
                </i>
                Decline
              </label>
            </div>
          </ng-container>
          <div class="btn-rounded bg-danger-soft" *ngIf="applicationStatus === 2">
            <label>
              <i class="bi bi-file-earmark-excel">
              </i>
              Declined
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" *ngIf="applicationStatus !== 2"
            (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchCase="'pending-registration'">
          <div class="btn-rounded bg-primary-soft" (click)="onApproveTalentClick()">
            <label>
              <i class="bi bi-patch-check">
              </i>
              Approve
            </label>
          </div>
          <div class="btn-border-rounded  btn-border-danger-soft" (click)="openRejetedModal()">
            <label>
              <i class="bi bi-x-circle-fill">
              </i>
              Reject
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchCase="'on-boarding-talent'">
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchDefault>
          <div class="btn-rounded bg-primary-soft" (click)="onGiveAnOfferClick()" *ngIf="isOfferingButtonShown">
            <label>
              <i class="bi bi-person-fill-add">
              </i>
              Give an Offer
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="userProfile.status && 'BO' | roleChecker">
        <svg (click)="editBasicInfo()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
            fill="#233D90" />
          <path
            d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
            fill="#233D90" />
          <path
            d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
            fill="#233D90" />
        </svg>
      </div>
    </div>
    <div class="basic5">
      <div class="portfolio">About</div>
      <div class="line-4"></div>
      <div class="content">
        <div class="about" *ngIf="userProfile.about">
          <div [innerHTML]="userProfile.about | safeHtml"></div>
        </div>
        <div *ngIf="userProfile.status && 'BO' | roleChecker">
          <svg *ngIf="userProfile.about" (click)="editAbout()" class="pencil-fill" width="16" height="16"
            viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
              fill="#233D90" />
            <path
              d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
              fill="#233D90" />
            <path
              d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
              fill="#233D90" />
          </svg>
          <div *ngIf="!userProfile.about" class="btn-rounded bg-primary-soft" (click)="editAbout()">
            <label>
              <i class="bi bi-plus-circle-fill">
              </i>
              Add bio
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="education">Education</div>
      </div>
      <div class="line-4"></div>
      <div class="ctn" *ngFor="let education of userProfile.education">
        <div class="job">
          <div class="when">
            <div class="smk-sekolah">{{ education.schoolName }} {{ education.class ? ' | ' + education.class : '' }}
            </div>
            <div class="august-2018-march-2020">{{ education.startDate | date: 'MMM y' }} - {{ education.stillAttend ?
              'Present' :
              education.endDate
              | date: 'MMM y' }}</div>
            <div class="_2-year-s-1-month-s">{{ education | timeRange }}</div>
            <div class="_2-year-s-1-month-s">{{ education.location }}</div>
          </div>
          <ng-container *ngIf="userProfile.status && 'BO' | roleChecker">
            <div class="actions">
              <svg (click)="editEducation(education)" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                  fill="#233D90" />
                <path
                  d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                  fill="#233D90" />
                <path
                  d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                  fill="#233D90" />
              </svg>
              <svg (click)="removeEducation(education)" class="trash-3-fill" width="16" height="16" viewBox="0 0 16 16"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                  fill="#CB3A31" />
              </svg>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="userProfile.status && 'BO' | roleChecker" class="btn-rounded bg-primary-soft"
        (click)="addNewEducation()">
        <label>
          <i class="bi bi-plus-circle-fill">
          </i>
          Add More
        </label>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="work-experience">Work Experience</div>
      </div>
      <div class="line-4"></div>
      <div class="ctn" *ngFor="let experience of userProfile.experience">
        <div class="job" #resize>
          <div class="when2">
            <div class="august-2018-march-20202">
              {{ experience.startDate | date: 'MMM y' }} - {{ experience.stillAttend ? 'Present' :
              experience.endDate | date: 'MMM y' }}
            </div>
            <div class="_2-year-s-1-month-s"> {{ experience | timeRange }}</div>
          </div>
          <div class="what">
            <div class="frame-1635">
              <div class="job-title">{{ experience.position }}</div>
              <div class="pt-company-indonesia"> {{ experience.companyName }}</div>
            </div>
            <div class="desc" *ngIf="experience.jobDetail">
              <div [innerHTML]="experience.jobDetail | safeHtml"></div>
            </div>
          </div>
          <div class="actions" *ngIf="userProfile.status && 'BO' | roleChecker">
            <svg (click)="editWorkHistory(experience)" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                fill="#233D90" />
              <path
                d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                fill="#233D90" />
              <path
                d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                fill="#233D90" />
            </svg>
            <svg (click)="removeWorkHistory(experience)" class="trash-3-fill" width="16" height="16" viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                fill="#CB3A31" />
            </svg>
          </div>
        </div>
      </div>
      <div *ngIf="userProfile.status && 'BO' | roleChecker" class="btn-rounded bg-primary-soft"
        (click)="addNewWorkHistory()">
        <label>
          <i class="bi bi-plus-circle-fill">
          </i>
          Add More
        </label>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="skill">Skill</div>
      </div>
      <div class="line-4"></div>
      <div class="div">
        <div class="hardskill">
          <div class="title2">
            <div class="sub">Hard Skill</div>
          </div>
          <div class="frame-1674">
            <div *ngFor="let item of userProfile.hardSkills" class="buttonrounded-stroked">
              <div class="button3">{{item.skill}}</div>
            </div>
          </div>
        </div>
        <div class="soft-skill">
          <div class="title2">
            <div class="sub">Soft Skill</div>
          </div>
          <div class="frame-1675">
            <div *ngFor="let item of userProfile.softSkills" class="buttonrounded-stroked">
              <div class="button3">{{item.skill}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userProfile.status && 'BO' | roleChecker" class="btn-rounded bg-primary-soft" (click)="editSkills()">
        <label>
          <i class="bi bi-plus-circle-fill">
          </i>
          Edit / Add More
        </label>
      </div>
    </div>
    <div class="portfolios">
      <div class="basic5">
        <div class="portfolio">Portfolio</div>
        <div class="line-4"></div>
        <div class="ctn2" *ngFor="let portfolio of userProfile.portofolio; let last = last">
          <div class="job">
            <div class="what">
              <div class="frame-16352">
                <div class="project-name">{{portfolio.projectName}}</div>
                <div class="august-2018-march-20203">
                  {{ portfolio.startDate | date: 'MMMM y' }} - {{ portfolio.isOngoing ? 'Present' :
                  portfolio.endDate
                  | date: 'MMMM y' }}
                </div>
              </div>
              <div class="desc2">
                <ng-container *ngIf="portfolio.description">
                  <div [innerHTML]="portfolio.description | safeHtml"></div>
                </ng-container>
                <div class="project-link-https-links-com">
                  {{ portfolio.url}}
                </div>
              </div>
            </div>
            <div class="actions" *ngIf="userProfile.status && 'BO' | roleChecker">
              <svg (click)="editPortfolio(portfolio)" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                  fill="#233D90" />
                <path
                  d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                  fill="#233D90" />
                <path
                  d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                  fill="#233D90" />
              </svg>
              <svg (click)="removePortfolio(portfolio)" class="trash-3-fill" width="16" height="16" viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                  fill="#CB3A31" />
              </svg>
            </div>
          </div>
          <div *ngIf="!last" class="line-5"></div>
        </div>
        <div *ngIf="userProfile.status && 'BO' | roleChecker" class="btn-rounded bg-primary-soft"
          (click)="addNewPortfolio()">
          <label>
            <i class="bi bi-plus-circle-fill">
            </i>
            Add More
          </label>
        </div>
        <div *ngIf="userProfile.portofolio.length > 1" class="line-52"></div>
        <div class="job">
          <div class="when3">
            <div class="personal-website">Personal Website</div>
            <div class="https-personal-com-si-paling-personalwebsitebro">
              {{ userProfile.resume?.personalWebsite || '-'}}
            </div>
          </div>
          <div class="actions" *ngIf="userProfile.status && 'BO' | roleChecker">
            <svg (click)="editPersonalWebsite()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                fill="#233D90" />
              <path
                d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                fill="#233D90" />
              <path
                d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                fill="#233D90" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="language">Language</div>
      </div>
      <div class="line-4"></div>
      <div class="div">
        <div class="frame-1674">
          <div *ngFor="let item of userProfile.languages" class="buttonrounded-stroked">
            <div class="button3">{{item.language}}</div>
          </div>
        </div>
        <div *ngIf="userProfile.status && 'BO' | roleChecker" class="btn-rounded bg-primary-soft"
          (click)="editLanguage()">
          <label>
            <i class="bi bi-plus-circle-fill">
            </i>
            Edit / Add More
          </label>
        </div>
      </div>
    </div>
  </div>
  <popup-confirmation id="reject-talent">
    <div class="popup popup-danger">
      <div class="popup-frame-1673">
        <div class="popup-title">
          <div class="popup-title-label popup-danger">Reject</div>
        </div>
        <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="popup-data-length">
        Talent
      </div>
      <div *ngIf="errorPopUpMessage" class="error-message">{{ errorPopUpMessage }}</div>
      <div class="popup-selected-items">
        <!-- <div *ngFor="let talent of selectedTalents; let i = index"> -->
        <div class="popup-user-profile-section">
          <div class="popup-user-profile">
            <div class="popup-pp">
              <img *ngIf="!userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
                class="popup-image" />
              <img *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl" class="popup-image" />
            </div>
          </div>
          <div class="popup-details">
            <div class="popup-name">{{userProfile.firstName}} {{userProfile.lastName}}</div>
            <div class="popup-as">
              <div class="popup-email">{{userProfile.email}}</div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Reason
        </div>
        <input [(ngModel)]="rejectReason" type="text" id="rejectReason" class="ec-form-control-rounded ec-is-invalid"
          placeholder="Input the rejected reason">
      </div>
      <div class="popup-button">
        <button type="button" (click)="rejectTalent()"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Okay, Reject
        </button>
        <button type="button" (click)="onCloseModal()"
          class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
          No, Turn Back
        </button>
      </div>
    </div>
  </popup-confirmation>
</div>
