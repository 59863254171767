import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '@helpers/storage.helper';
import { USER_TYPE } from '../constants';

@Pipe({
  name: 'roleChecker'
})
export class RoleCheckerPipe implements PipeTransform {

  constructor(private router: Router,) { }

  transform(value: any): any {
    const accessToken = StorageHelper.getAccessToken();
    if (!accessToken) {
      this.router.navigate(['/auth/login']);
    }

    const loggedUser = StorageHelper.getCurrentLoggedUser();
    const isTalentAcquisition = loggedUser?.role === 'Talent Acquisition';
    const isUser = loggedUser?.role === 'User';
    const isBackOffice = loggedUser?.type === USER_TYPE.BACK_OFFICE;

    switch (value) {
      case 'TA':
        return isTalentAcquisition;
      case 'USER':
        return isUser;
      case 'BO':
        return isBackOffice;
      default:
        return false;
    }
  }
}
