<div>
  <div class="form-container">
    <app-spinner [isPositionFixed]="!'BO' | roleChecker" *ngIf="isLoading"></app-spinner>
    <div class="form-title">
      <div class="title-content">
        <div class="title-text">
          Company Profile
        </div>
        <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="break-line">
      </div>
    </div>
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <form [formGroup]="basicInfoForm" class="basic-form-body" (ngSubmit)="onClickUpdate()">
      <div class="profile-picture-wrapper">
        <div class="corpo-profile-section">
          <img title="Edit Company Photo" *ngIf="oldBasicInfo && oldBasicInfo.profilePictureUrl"
            [src]="oldBasicInfo.profilePictureUrl" class="profile-picture" (click)="openFileInput()" />
          <img title="Edit Company Photo" *ngIf="!oldBasicInfo.profilePictureUrl"
            src="./assets/img/project/default-company-logo.png" class="profile-picture" (click)="openFileInput()" />
          <input type="file" style="display: none;" id="fileInput" (change)="onFileSelected($event)"
            accept=".png, .jpg, .jpeg">
        </div>
        <div class="profile-term">
          Acceptable format : <br />.png .jpeg .jpg <br />With size not exceeding 10 MB
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Company Name
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-c-circle-fill"></i>
          </span>
          <input type="text" formControlName="companyName" class="ec-form-control-rounded ec-is-invalid"
            placeholder="Company Name">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('companyName')?.touched && basicInfoForm.get('companyName')?.hasError('required')">
            Company name is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('companyName')?.touched && basicInfoForm.get('companyName')?.hasError('maxlength')  && !basicInfoForm.get('companyName')?.hasError('pattern')">
            Company name length maximum {{ 'INPUT' | maxLength }} characters
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('companyName')?.touched && basicInfoForm.get('companyName')?.hasError('pattern')">
            Company Name should be a alphanumeric and minimum 3 characters
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Brand
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-building-fill"></i>
          </span>
          <input type="text" formControlName="brand" class="ec-form-control-rounded ec-is-invalid" placeholder="brand">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('brand')?.touched && basicInfoForm.get('brand')?.hasError('required')">
            Brand is required
          </div>

          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('brand')?.touched && basicInfoForm.get('brand')?.hasError('maxlength') && !basicInfoForm.get('brand')?.hasError('pattern')">
            Company name length maximum {{ 'INPUT' | maxLength }} characters
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('brand')?.touched && basicInfoForm.get('brand')?.hasError('pattern')">
            Brand length should be a alphanumeric and minimum 3 characters
          </div>
        </div>
      </div>
      <div class="break-line" style="margin: 10px 0px;">
      </div>
      <div class="button-group">
        <button type="submit" [disabled]="!basicInfoForm.valid || isLoading"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </form>
  </div>
</div>
