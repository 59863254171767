import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { ValidationHelper } from '@helpers/validation.helper';
import { CompanyBasicInfo } from '@interfaces/corporate.interface';
import { Reference } from '@interfaces/reference.interface';
import { Timezone } from '@interfaces/register.interface';
import { BackOfficeService } from '@services/back-office.service';
import { CorporateService } from '@services/corporate.service';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['./basic-info-form.component.scss', '/../../../talent/about/about.component.scss']
})
export class BasicInfoFormComponent implements AfterViewInit {
  public oldBasicInfo !: CompanyBasicInfo;
  public isLoading = false;
  public errorMessage = '';
  public basicInfoForm !: FormGroup;
  public employmentStatuses: Reference[] = [];
  public timezoneList: Timezone[] = [];
  public selectedFile: File | null = null;
  public selectedFileName: string | null = null;
  public isUploadingResume = false;

  constructor
    (
      private corporateService: CorporateService,
      private backOfficeService: BackOfficeService,
      private dialogRef: MatDialogRef<BasicInfoFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data) {
      this.oldBasicInfo = modalData.data.basicInfo;
      this.mapEditedBasicInfo()
    }
  }

  private mapEditedBasicInfo() {
    this.basicInfoForm = new FormGroup({
      companyName: new FormControl<string>({
        value: this.oldBasicInfo.companyName,
        disabled: false
      }, {
        validators: [
          Validators.required, Validators.pattern(ALPHANUMERIC_PATTERN), Validators.maxLength(MAX_LENGTH.INPUT)
        ], nonNullable: true
      }),
      brand: new FormControl<string | null>({
        value: this.oldBasicInfo.brand,
        disabled: false
      }, {
        validators: [Validators.required, Validators.pattern(ALPHANUMERIC_PATTERN), Validators.maxLength(MAX_LENGTH.INPUT)],
        nonNullable: false
      }),
    },);
  }


  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  private mapBasicInfoPayload(): Partial<CompanyBasicInfo> {
    const companyName = this.basicInfoForm.get('companyName')?.getRawValue();
    const brand = this.basicInfoForm.get('brand')?.getRawValue();
    return {
      companyName,
      brand,
    }
  }

  openFileInput() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  public async onFileSelected(event: any) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = inputFile.files[0].name;
      this.isUploadingResume = true;
      try {
        ValidationHelper.uploadFileValidator(inputFile)
        if (StorageHelper.isBackOfficeRole) {
          await this.backOfficeService.updateCompanyProfilePicture(this.oldBasicInfo.id, this.selectedFile);
        } else {
          await this.corporateService.updateProfilePicture(this.oldBasicInfo.id, this.selectedFile);
        }
        await this.modalData.callback();
        this.dialogRef.close()
        this.toastManager.showSuccess('Photo profile updated successfully')
      } catch (error: any) {
        this.toastManager.showError('Upload failed', error.message)
      } finally {
        this.isUploadingResume = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
    }
  }

  public async onClickUpdate() {
    try {
      this.isLoading = true;
      const newBasicInfo = this.mapBasicInfoPayload();
      if (StorageHelper.isBackOfficeRole) {
        await this.backOfficeService.editCompanyBasicInfo(this.oldBasicInfo.id, newBasicInfo);
      } else {
        await this.corporateService.editBasicInfo(newBasicInfo);
      }
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Company Profile updated successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
