import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { ValidationHelper } from '@helpers/validation.helper';
import { CompanyProfile } from '@interfaces/corporate.interface';
import { BasicInfo } from '@interfaces/talent.interface';
import { ConfirmationDialogComponent } from '@modals/confirmation-dialog/confirmation-dialog.component';
import { BackOfficeService } from '@services/back-office.service';
import { CorporateService } from '@services/corporate.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-photo-profile-form',
  templateUrl: './photo-profile-form.component.html',
  styleUrls: ['./photo-profile-form.component.scss', '../../../talent/about/about.component.scss']
})
export class PhotoProfileFormComponent {

  public oldBasicInfo !: BasicInfo;
  public oldCompanyProfile !: CompanyProfile;
  public isLoading = false;
  public errorMessage = '';
  public basicInfoForm !: FormGroup;
  public selectedFile: File | null = null;
  public talentId !: string;
  public selectedFileName: string | null = null;
  public loginAs = 'TALENT';
  private destroy$ = new Subject<void>();

  constructor
    (
      private talentService: TalentService,
      private backOfficeService: BackOfficeService,
      private corporateService: CorporateService,
      private dialogRef: MatDialogRef<PhotoProfileFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
      private storeService: StoreService,
      public dialog: MatDialog
    ) {
    if (modalData?.data) {
      this.oldBasicInfo = modalData.data.basicInfo;
      this.talentId = modalData.data.talentId;
      this.oldCompanyProfile = modalData.data.basicInfo;
    }
  }

  ngOnInit() {
    this.storeService.loginAs$
      .pipe(takeUntil(this.destroy$))
      .subscribe(loginAs => {
        this.loginAs = loginAs;
      })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  openFileInput() {
    const fileInput = document.getElementById('profilePicture');
    if (fileInput) {
      fileInput.click();
    }
  }

  public async onFileSelected(event: any) {
    const inputFile = event.target as HTMLInputElement;
    this.isLoading = true;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = inputFile.files[0].name;
      try {
        if (this.loginAs === 'TALENT') {
          ValidationHelper.uploadFileValidator(inputFile);
          await this.talentService.updateProfilePicture(this.selectedFile);
          this.toastManager.showSuccess("Profile picture updated successfully")
        }

        if (this.loginAs === 'CORPORATE') {
          ValidationHelper.uploadFileValidator(inputFile);
          if (this.oldCompanyProfile.id) {
            await this.corporateService.updateProfilePicture(this.oldCompanyProfile.id, this.selectedFile);
            this.toastManager.showSuccess("Company picture updated successfully")
          }
        }

        if (StorageHelper.isBackOfficeRole && this.talentId) {
          ValidationHelper.uploadFileValidator(inputFile);
          await this.backOfficeService.updateTalentProfilePicture(this.talentId, this.selectedFile);
          this.toastManager.showSuccess("Talent picture updated successfully");
          this.talentId = '';
        }

        if (StorageHelper.isBackOfficeRole && this.oldCompanyProfile.id) {
          ValidationHelper.uploadFileValidator(inputFile);
          await this.backOfficeService.updateCompanyProfilePicture(this.oldCompanyProfile.id, this.selectedFile);
          this.toastManager.showSuccess("Corporate picture updated successfully");
        }

        await this.modalData.callback();
        this.dialogRef.close();
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
    }
  }

  public async removePhotoProfile() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: ` Are you sure you want to remove this photo?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          if (this.loginAs === 'TALENT') {
            await this.talentService.removeProfilePicture();
            this.toastManager.showSuccess("Profile picture removed successfully")
          }

          if (this.loginAs === 'CORPORATE') {
            await this.corporateService.removeProfilePicture();
            this.toastManager.showSuccess("Profile picture removed successfully")
          }

          if (StorageHelper.isBackOfficeRole && this.talentId) {
            await this.backOfficeService.removeTalentProfilePicture(this.talentId);
            this.toastManager.showSuccess("Talent picture removed successfully")
          }


          if (StorageHelper.isBackOfficeRole && this.oldCompanyProfile.id) {
            await this.backOfficeService.removeCompanyProfilePicture(this.oldCompanyProfile.id);
            this.toastManager.showSuccess("Company picture removed successfully")
          }

          await this.modalData.callback();
          this.dialogRef.close();
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    })
  }
}
