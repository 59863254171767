// Angular modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// External modules
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
// BOOTSTRAP CONFIG
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { ToastComponent } from './components/blocks/toast/toast.component';
import { ProgressBarComponent } from './components/blocks/progress-bar/progress-bar.component';
import { GraduateComponent } from './components/cards/graduate/graduate.component';

// Forms
import { FormConfirmComponent } from './components/forms/form-confirm/form-confirm.component';

// Modals
import { ModalWrapperComponent } from './components/modals/modal-wrapper/modal-wrapper.component';

// Layouts
import { TalentHeaderComponent } from './components/layouts/talent/layout-header/layout-header.component';
import { TalentLayoutComponent } from './components/layouts/talent/page-layout/page-layout.component';

// Pipes
import { StartCasePipe } from './pipes/start-case.pipe';
import { InitialNamePipe } from './pipes/initial-name.pipe';
import { TimeRangePipe } from './pipes/time-range.pipe';
import { MaxLengthPipe } from '@pipes/maxLength.pipe';
import { StringCasePipe } from '@pipes/string-case.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { CorporateRolerPipe } from '@pipes/corporate-role.pipe';

// Directives
import { ModalWrapperDirective } from './directives/modal-wrapper.directive';

import { MatCardModule } from '@angular/material/card';
import { SpinnerComponent } from './components/blocks/spinner/spinner.component';
import { CommonModalComponent } from './components/modals/common-modal/common-modal.component';
import { JobCardComponent } from './components/cards/job-card/job-card.component';
import { FlattenArrayPipe } from '@pipes/flaten-array.pipe';
import { NavbarComponent } from '@layouts/navbar/navbar.component';
import { TalentNavbarComponent } from '@layouts/navbar/talent-navbar/talent-navbar.component';
import { FooterComponent } from '@layouts/footer/footer.component';
import { TimeAgoPipe } from '@pipes/time-ago.pipe';
import { TooltipComponent } from './components/blocks/tooltip/tooltip.component';
import { ErrorPopupConfirmationComponent } from './components/blocks/error-popup-confirmation/error-popup-confirmation.component';
import { SearchBarComponent } from '@layouts/search-bar/search-bar.component';
import { JobListComponent } from '@layouts/job-list/job-list.component';
import { EmptyResultComponent } from '@layouts/empty-result/empty-result.component';
import { CorporateSearchBarComponent } from '@layouts/corporate-search-bar/search-bar.component';
import { TalentCardComponent } from './components/cards/talent-card/talent-card.component';
import { TalentListComponent } from './components/layouts/talent-list/talent-list.component';
import { TalentResumeComponent } from './components/forms/talent-resume/talent-resume.component';
import { ActiveJobSearchBarComponent } from './components/layouts/active-job-search-bar/active-job-search-bar.component';
import { PopupConfirmationComponent } from './components/blocks/popup-confirmation/popup-confirmation.component';
import { AccountListComponent } from './components/cards/account-list/account-list.component';
import { EmptyTableComponent } from './components/layouts/empty-table/empty-table.component';
import { TodoCardComponent } from './components/cards/todo-card/todo-card.component';
import { JobBenefitComponent } from './components/forms/job-benefit/job-benefit.component';
import { OnBoardingSearchBarComponent } from './components/layouts/on-boarding-search-bar/on-boarding-search-bar.component';
import { RecruitmentStepsComponent } from './components/layouts/recruitment-steps/recruitment-steps.component';
import { JobComponent } from './components/forms/job/job.component';
import { TechnicalTestComponent } from './components/forms/technical-test/technical-test.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InterviewFormComponent } from './components/forms/interview-form/interview-form.component';
import { UnAuthenticatedPageComponent } from './components/layouts/un-authenticated-page/un-authenticated-page.component';
import { DeviceDetectorPipe } from '@pipes/device-detector.pipe';
import { RecruitmentSvgComponent } from './components/cards/recruitment-svg/recruitment-svg.component';
import { OnBoardingFormComponent } from './components/forms/on-boarding-form/on-boarding-form.component';
import { PlacementAssigneeComponent } from './components/blocks/placement-assignee/placement-assignee.component';
import { NotificationComponent } from './components/cards/notification/notification.component';
import { PaginationComponent } from './components/cards/pagination/pagination.component';
import { JobDetailComponent } from './components/modals/job-detail/job-detail.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { NavigateDirective } from '@directives/navigate.directive';
import { RoleCheckerPipe } from '@pipes/role-checker.pipe';


@NgModule({
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // External modules
    TranslateModule,
    AngularSvgIconModule,
    NgbModule,
    MatCardModule,
    CKEditorModule,
  ],
  declarations: [
    // Components
    ToastComponent,
    ProgressBarComponent,
    GraduateComponent,
    EmptyResultComponent,

    // Forms
    FormConfirmComponent,
    JobComponent,
    TechnicalTestComponent,
    InterviewFormComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    TalentHeaderComponent,
    TalentLayoutComponent,
    SearchBarComponent,
    JobListComponent,
    CorporateSearchBarComponent,

    // Pipes
    TimeRangePipe,
    StartCasePipe,
    InitialNamePipe,
    MaxLengthPipe,
    FlattenArrayPipe,
    TimeAgoPipe,
    StringCasePipe,
    SafeHtmlPipe,
    DeviceDetectorPipe,
    CorporateRolerPipe,
    RoleCheckerPipe,

    // Directives
    ModalWrapperDirective,
    NavigateDirective,
    SpinnerComponent,
    CommonModalComponent,
    JobCardComponent,
    NavbarComponent,
    FooterComponent,
    TooltipComponent,
    TalentNavbarComponent,
    ErrorPopupConfirmationComponent,
    TalentCardComponent,
    TalentListComponent,
    TalentResumeComponent,
    ActiveJobSearchBarComponent,
    PopupConfirmationComponent,
    AccountListComponent,
    EmptyTableComponent,
    TodoCardComponent,
    JobBenefitComponent,
    OnBoardingSearchBarComponent,
    RecruitmentStepsComponent,
    UnAuthenticatedPageComponent,
    RecruitmentSvgComponent,
    OnBoardingFormComponent,
    PlacementAssigneeComponent,
    NotificationComponent,
    PaginationComponent,
    JobDetailComponent,
    ConfirmationDialogComponent,
  ],
  exports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // External modules
    TranslateModule,
    AngularSvgIconModule,
    NgbModule,

    // Components
    ToastComponent,
    ProgressBarComponent,
    SpinnerComponent,
    GraduateComponent,
    JobCardComponent,
    NavbarComponent,
    TalentNavbarComponent,
    FooterComponent,
    TooltipComponent,
    ErrorPopupConfirmationComponent,
    EmptyResultComponent,
    CorporateSearchBarComponent,
    TalentCardComponent,
    TalentListComponent,
    PopupConfirmationComponent,
    AccountListComponent,
    TodoCardComponent,
    JobBenefitComponent,
    OnBoardingSearchBarComponent,
    RecruitmentStepsComponent,
    RecruitmentSvgComponent,
    PlacementAssigneeComponent,
    NotificationComponent,
    PaginationComponent,
    JobDetailComponent,

    // Forms
    FormConfirmComponent,
    TalentResumeComponent,
    EmptyTableComponent,
    JobComponent,
    TechnicalTestComponent,
    InterviewFormComponent,
    OnBoardingFormComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    TalentHeaderComponent,
    TalentLayoutComponent,
    SearchBarComponent,
    JobListComponent,
    ActiveJobSearchBarComponent,
    UnAuthenticatedPageComponent,

    // Pipes
    TimeRangePipe,
    StartCasePipe,
    InitialNamePipe,
    MaxLengthPipe,
    FlattenArrayPipe,
    TimeAgoPipe,
    StringCasePipe,
    SafeHtmlPipe,
    DeviceDetectorPipe,
    CorporateRolerPipe,
    RoleCheckerPipe,

    // Directives
    ModalWrapperDirective,
    NavigateDirective
  ],
  providers: [
  ]
})
export class SharedModule { }
